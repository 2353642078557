import type { UserRole } from "~/utils/consts";

export default function useSessionInfo() {
  const { $ory } = useNuxtApp();

  const { session } = $ory;

  const username = computed(() => {
    return session.value?.identity?.traits.username;
  });
  const userId = computed(() => {
    return session.value?.identity?.id;
  });

  const loggedIn = computed(() => {
    return session.value !== null;
  });

  const userRole = computed(() => {
    const metadataPublic = session.value?.identity?.metadata_public as { role: UserRole };
    return metadataPublic?.role;
  });

  return {
    username,
    userId,
    loggedIn,
    userRole
  };
}
